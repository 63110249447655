<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="bianHao" label="编号" />
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="dongJieYuanYin" label="冻结原因" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="dongJieSJ" label="冻结时间" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/DongJieJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                bianHao: "", // 编号
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                dongJieYuanYin: "", // 冻结原因
                caoZuoRen: "", // 操作人
                dongJieSJ: "", // 冻结时间
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>